import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Settings',
        language: 'Language',
        dashboard: {

        },
        menu: {
            catalog: 'Catalog',
            sessions: 'Sessions',
        },
    },
    resources: {
        exercises: {
            name: 'Exercises'
        },
        sessions: {
            generate: 'Generate',
            execute: 'Execute'
        },
        equipments: { // Keep snake case
            name: 'Equipments',
            pull_up_bar: 'Pull up bar',
            dumbbell: 'Dumbbell',
            stepups_box: 'Step-ups box',
            bike: 'Bike',
            kettlebell: 'Kettlebell',
            resistance_band: 'Resistance band',
            ankle_wrist_weights: 'Ankle/wrist weights',
            medicine_ball: 'Medicine ball',
            dips_bar: 'Dips bar',
            swiss_ball: 'Swiss ball',
            tennis_ball: 'Tennis ball',
            waff: 'Waff',
            water_bottle: 'Water bottle',
            stool: 'Stool',
            chair: 'Chair',
            chair_with_armrests: 'Chair with armrests',
            table: 'Table',
            weighted_backpack: 'Weighted backpack',
            pillar: 'Pillar',
            wall: 'Wall',
            towels: 'Towels',
            musculation_bench: 'Musculation bench',
        },
        sessionGenerator: {
            showValidSessions: 'Display valid sessions.',
            incorrectPauseBefore: 'Incorrect pause duration before `%{exerciseVariantId}` in %{section} section. Should be %{validPause} s but %{foundPause} found.',
            unilateralVariantExerciseNotPaired: "Component `%{exerciseVariantId}` in %{section} is unilateral and not paired with the other variant.",
            unknownVariantId: "Unknown exercise variant id: %{exerciseVariantId}",
            unsatisfiedEquipmentWithNoUserEquipments: "The exercise `%{exerciseVariantId}` requires `%{requiredEquipment}` equipment. But user hasn't got any.",
            unsatisfiedEquipment: "The exercise `%{exerciseVariantId}` requires `%{requiredEquipment}` equipment. But user has only %{userEquipments}",
            incorrectNumberOfComponentInExercisesSection: "The number of component in the exercise section is incorrect. It should be equal to 10 times the number of iterations. Expected number: %{expectedNumberOfComponents}. Found number: %{foundNumberOfComponents}",
            incorrectExerciseDuration: 'Component `%{exerciseVariantId}` in  %{section} should last %{expectedDuration} s. Found %{foundDuration}.',
            unsatisfiedPainAreaCondition: "The exercise `%{exerciseVariantId}` in %{section} stresses areas for which the user has indicated to feel pain: %{painAreas}.",
        },
    }
};

export default customEnglishMessages;