import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import { List, Title, useGetOne, useRedirect } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Check, Subscriptions } from '@mui/icons-material';
import {
  Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Paper, Stack,
  TextField, Typography
} from '@mui/material';

import inviteCodes from '../inviteCodes';
import { theme } from '../layout/themes';
import users from '../users';
import { callAPI } from '../utils/useAdminApi';
import ProgramForm from './ProgramForm';

const MenuBlocks = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const program = props.program

  console.log(theme);
  const items = [
    { id: 1, title: translate(`pos.menu.codes`), Icon: inviteCodes.icon, path: `/${program.id}/invite-codes` },
    { id: 2, title: translate(`pos.menu.users`), Icon: users.icon, path: `/${program.id}/users` },
    { id: 3, title: translate(`pos.menu.sessions`), Icon: Subscriptions, path: `/${program.id}/sessions/generate` },
  ];

  return (
    <Box display="flex" flexWrap="wrap" alignContent="flex-start">
      {items.map(({ id, title, Icon, path }) => (
        <Box key={id} m={1} onClick={() => redirect(path)} style={{ cursor: 'pointer' }}>
          <Paper
            style={{
              width: '200px',
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.contrastText
            }}>
            <Icon style={{ marginBottom: '10px' }} />
            <Typography variant="h5">{title}</Typography>
          </Paper>
        </Box>
      ))}
    </Box>
  )
}


export const ProgramShow = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [deletedProgramData, setDeletedProgramData] = useState<any>(null);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [copySuccess, setCopySuccess] = useState<any>(false);

  const { programId } = useParams();
  const { control, handleSubmit, register, watch } = useForm({ defaultValues: { confirmDelete: "" } });

  const { data, isLoading } = useGetOne(
    'AlixMedPrograms',
    { id: programId },
    { onError: () => <div>Error</div> }
  );

  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }

  async function handleDelete() {
    // Reset states
    setDeleteError(null);
    setDeletedProgramData(null);

    // Try to delete the program
    const { response, error } = await callAPI({ apiPath: `/adminApi/program/${programId}`, type: 'delete' });

    // Set results and toggle dialogs
    setDeleteDialogOpen(false);

    if (response) {
      setDeletedProgramData(response.deletedItem);
      setDeleteSuccessOpen(true);
    } else {
      setDeleteError(error);
    }

  }

  function copyToClipboard() {
    navigator.clipboard.writeText(JSON.stringify(deletedProgramData)).then(() => {
      setCopySuccess(true)
      setTimeout(() => redirect('/'), 3000);
    });
  };


  return (
    <div style={{ padding: "16px 0" }}>

      {/* Program data */}
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        {data &&
          <>
            <Title title={translate(`pos.programShowTitle`, { programName: data.displayName })} />
            <h1>{data.displayName}</h1>
            <ProgramForm edit programData={data} />
          </>
        }
        <MenuBlocks program={data} />
      </Stack>


      {/* Delete dialog button */}
      <div style={{ position: 'absolute', bottom: 24, right: 24 }}>
        <Button variant='contained' color='error' onClick={() => setDeleteDialogOpen(true)}>{translate('resources.programs.delete.button')}</Button>
      </div>
      <Dialog open={deleteDialogOpen} maxWidth='md'>
        <DialogContent>
          <form onSubmit={handleSubmit((data) => handleDelete())} style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
            <Alert severity='warning' sx={{ display: 'flex' }}>
              <Typography variant='subtitle1'>{translate('resources.programs.delete.confirmTitle')}:</Typography>
              <Typography variant='h3' textAlign='center'>{programId}</Typography>
            </Alert>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField {...register('confirmDelete')} sx={{ maxWidth: 'fit-content' }} size='small' fullWidth />
            </div>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant='contained' onClick={() => setDeleteDialogOpen(false)} autoFocus>{translate('resources.misc.cancel')}</Button>
              <Button variant='outlined' color='error' type='submit'
                disabled={watch('confirmDelete') !== programId}
              >{translate('resources.programs.delete.confirm')}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete feedback */}
      {deleteError &&
        <Alert severity='error'>{JSON.stringify(deleteError)}</Alert>
      }

      {/* Post-deletion dialog */}
      <Dialog open={deleteSuccessOpen} maxWidth='md'>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, padding: 2 }}>
          <Typography variant='subtitle1'>{translate('resources.programs.delete.copyProgram')}</Typography>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant='contained' onClick={() => copyToClipboard()} autoFocus color={copySuccess ? 'success' : undefined}>
              {copySuccess ?
                <Check />
                : translate('resources.programs.delete.copy')
              }
            </Button>
            <Button variant='contained' onClick={() => redirect('/')} >{translate('resources.programs.delete.goBack')}</Button>
          </DialogActions>
        </DialogContent>

      </Dialog>
    </div >
  );
};

export default ProgramShow;