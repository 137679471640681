import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import LaunchIcon from '@mui/icons-material/Launch';
import {
    Alert, Button, CardActionArea, CardContent, CircularProgress, Dialog, DialogActions,
    DialogContent, Grid, Paper, Typography
} from '@mui/material';

import { postAsyncData } from '../utils/useAdminApi';

export type UserRecommendations = {
    value: string,
    createdAt: string,
    expiresAt: string,
}

export default function UserRecommendations({
    userRecommendations,
    userId
}: {
    userRecommendations: UserRecommendations | undefined
    userId: string | undefined
}) {
    // Load translation
    const translate = useTranslate();

    // Declare states
    const [recommendations, setRecommendations] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch user's recommendations
    useEffect(() => {
        if (userRecommendations === undefined) return;
        async function fetchRecommendations() {
            setRecommendations(userRecommendations?.value);
        }
        fetchRecommendations();
    }, [userId]);

    async function generateNewRecommendations(userId) {
        // Set the loading state to true before calling the function
        setLoading(true);

        // Call API with a POST
        const { data, error: postError } = await postAsyncData("/adminApi/user/" + userId + "/recommendations", { updateTable: true }) as { data: any, error: any };
        setLoading(false);

        // Parse the result data
        const newRecommendations = JSON.parse(data ? data.body : {});

        if (postError !== undefined) {
            console.log('postError', postError);
            setError(postError);
            return;
        } else if (newRecommendations.error) {
            console.log('newRecommendations ERROR', newRecommendations);
            setError(newRecommendations.error);
            return;
        }

        console.log('newRecommendations', newRecommendations);
        // Set the new recommendations to the state
        setRecommendations(newRecommendations.value);
    }

    return (
        <>
            <CardActionArea onClick={() => setOpen(true)}>
                <CardContent sx={{ display: 'flex', padding: 0 }}>
                    <Paper sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 2, gap: 1 }}>
                        <Typography variant="h5">{translate('resources.users.recommendations.title')}</Typography>
                        <LaunchIcon />
                    </Paper>
                </CardContent>
            </CardActionArea >
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="Recommendations"
                maxWidth={"lg"}
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h4'>{translate('resources.users.recommendations.title')}</Typography>
                            {/* <Typography variant='body1'></Typography> */}
                            <ReactMarkdown>{recommendations}</ReactMarkdown>
                            {error && <Alert severity='error'>{error}</Alert>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div style={{ flex: '1 0 0', display: 'flex', alignItems: 'center' }}>
                        <Button variant='outlined' onClick={async () => generateNewRecommendations(userId)} autoFocus disabled={!userId || loading}>{translate('resources.users.recommendations.generateNew')}</Button>
                        {loading && <CircularProgress size={25} thickness={2} />}
                    </div>
                    <Button variant='contained' onClick={() => setOpen(false)} autoFocus>{translate('resources.misc.close')}</Button>
                </DialogActions>

            </Dialog>
        </>

    )
}