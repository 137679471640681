import QrCodeIcon from '@mui/icons-material/QrCode';

import CodeList from './CodeList';
import CodeShow from './CodeShow';
import CodeEdit from './CodeEdit';

export default {
  list: CodeList,
  show: CodeShow,
  edit: CodeEdit,
  icon: QrCodeIcon,
};
