import React, { ElementType, useState } from "react";
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepContent,
  Box,
  Skeleton,
  Typography,
  StepIconProps,
} from '@mui/material';
import { useTranslate } from "ra-core";
import Moment from 'react-moment';
import 'moment/locale/fr';
import moment from 'moment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import TimerIcon from '@mui/icons-material/Timer';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';


export default function UserSessionsTimeline(props) {
  const translate = useTranslate();

  return (
    <Stepper orientation="vertical">
      {props.sessions.items.map((session, index) => (
        <Step
          key={index}
          expanded={true}
          active={true}
        >
          <StepLabel
            StepIconComponent={FitnessCenterIcon as ElementType<StepIconProps>}
            optional={
              <Typography variant="caption">
                <Moment unix fromNow locale="fr">{session.timestamp / 1000}</Moment>
              </Typography>
            }
          >
            {translate(`resources.sessions.sessionModule`,
              {
                module: translate(`resources.modules.${session.sessionInfo.module}`)
              }
            )}
          </StepLabel>
          <StepContent>
            <Typography variant="body2" component="p">
              <TimerIcon fontSize="inherit" /> {moment.utc(1000 * session.exercisesData.duration).format('mm[:]ss')}
            </Typography>
            <Typography variant="body2" component="p">
              <ThumbUpAltIcon fontSize="inherit" /> {session.sessionInfo.feedback}
            </Typography>

          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}