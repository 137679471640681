import React from "react";
import {
  Typography,
  Box,
} from '@mui/material';
import { useTranslate } from "ra-core";
import 'moment/locale/fr';
import { BarChart, LabelList, Legend, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Text, CartesianGrid } from 'recharts';


export default function ActivityChart(props) {
  const translate = useTranslate();
  const chartData = props.data
  const dataType = props.type

  const YAxisTick = (props) => {
    const { x, y, payload } = props
    return (
      <Text x={x - 16} y={y} width={75} fontSize={10} textAnchor="middle" verticalAnchor="start">
        {`${payload.value}${dataType == "activity" ? translate(`resources.users.charts.unitActivity`) : translate(`resources.users.charts.unitSteps`)}`}
      </Text>
    )
  }

  const LabelListData = (props) => {
    const { x, y, width, height, value } = props;
    return (
      <Text x={x + 4} y={y - 5} width={75} fontSize={11}>
        {`${value}${dataType == "activity" ? translate(`resources.users.charts.unitActivity`) : translate(`resources.users.charts.unitSteps`)}`}
      </Text>
    )
  }

  const LegendBox = (props) => {
    const { payload } = props;
    return (
      <ul>
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: "#8884d8", listStyle: "square" }}>{dataType == "activity" ? translate('resources.users.charts.activityLegend') : translate('resources.users.charts.stepsLegend')}</li>
          ))
        }
      </ul>
    )
  }

  const TooltipBox = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box p={1} px={2} sx={{ backgroundColor: "#FFF", color: "#000", borderRadius: 4, textTransform: "capitalize" }}>
          <Typography variant="h6">{payload[0].payload.dateFull}</Typography>
          <Typography>{`${payload[0].value}${dataType == "activity" ? translate(`resources.users.charts.unitActivity`) : translate(`resources.users.charts.unitSteps`)}`}</Typography>
        </Box>
      )
    }
    return null;
  }

  return (
    <Box>
      <Typography
        mb={2}
        variant={"h6"}>
        {dataType == "activity" ? translate('resources.users.charts.activityTitle') : translate('resources.users.charts.stepsTitle')}
      </Typography>
      <ResponsiveContainer height={300}>
        <BarChart data={chartData}>
          <CartesianGrid stroke="#CCCCCC30" strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis tick={<YAxisTick />} />
          <Tooltip
            content={<TooltipBox active={undefined} payload={undefined} label={undefined} />}
            cursor={{ stroke: '#8884d8', strokeWidth: 2, strokeOpacity: 0.3, fill: '#8884d820' }}
          />
          <Legend content={<LegendBox />} />
          <Bar dataKey={dataType} fill="#8884d8" radius={8}>
            <LabelList dataKey={dataType} position="top" content={<LabelListData />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}