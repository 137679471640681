import React, { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslate } from "ra-core";
import 'moment/locale/fr';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CircleIcon from '@mui/icons-material/Circle';
import OutboundIcon from '@mui/icons-material/Outbound';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

export default function UserSessionsDisplay(props) {
  const translate = useTranslate();
  const sessions = props.sessions
  const exercisesVariantsList = props.exercisesVariantsList
  const showDetails = props.showDetails

  const CollapsibleInformationSet = (props) => {
    const { session } = props

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    const logsIcons = {
      "walk": <DirectionsWalkIcon />,
      "indoor": <FitnessCenterIcon />
    }

    const renderSessionTitle = () => {
      switch (session.type) {
        case "walk":
          return session?.sessionInfo?.sessionsCompleted ?
            translate('resources.users.sessions.walkSessionNumberOf', { count: session.sessionInfo.sessionsCompleted, date: moment(session.timestamp).locale('fr').format("ddd D MMMM YYYY - HH:mm") })
            : translate('resources.users.sessions.walkSessionOf', { date: moment(session.timestamp).locale('fr').format("ddd D MMMM YYYY - HH:mm") })

        case "indoor":
          return session?.sessionInfo?.sessionsCompleted ?
            translate('resources.users.sessions.indoorSessionNumberOf', { count: session.sessionInfo.sessionsCompleted, date: moment(session.timestamp).locale('fr').format("ddd D MMMM YYYY - HH:mm") })
            : translate('resources.users.sessions.indoorSessionOf', { date: moment(session.timestamp).locale('fr').format("ddd D MMMM YYYY - HH:mm") })
        default:
          return session?.sessionInfo?.sessionsCompleted ?
            translate('resources.users.sessions.sessionNumberOf', { count: session.sessionInfo.sessionsCompleted, date: moment(session.timestamp).locale('fr').format("ddd D MMMM YYYY - HH:mm") })
            : translate('resources.users.sessions.sessionOf', { date: moment(session.timestamp).locale('fr').format("ddd D MMMM YYYY - HH:mm") })
      }
    }

    const renderWalkData = () => {
      const generalSessionWalkData = session.exercisesData.walkSessionData;
      const exerciseComponentsData = session.exercisesData.components;

      const walkComponentTypeName = (component) => {        
        let typeName
        switch (component.exerciseId) {
          case "walk_easy":
            typeName =
              <Typography noWrap>
                <CircleIcon sx={{ fontSize: '.8em', verticalAlign: 'text-top' }} color="success" />
                {translate('resources.users.sessions.walkEasy')}
              </Typography>
            break;
          case "walk_medium":
            typeName = <Typography noWrap>
              <CircleIcon sx={{ fontSize: '.8em', verticalAlign: 'text-top' }} color="warning" />
              {translate('resources.users.sessions.walkMedium')}
            </Typography>
            break;
          case "walk_hard":
            typeName = <Typography noWrap>
              <CircleIcon sx={{ fontSize: '.8em', verticalAlign: 'text-top' }} color="error" />
              {translate('resources.users.sessions.walkHard')}
            </Typography>
            break;
          case "walk_very_hard":
            typeName = <Typography noWrap>
              <CircleIcon sx={{ fontSize: '.8em', verticalAlign: 'text-top' }} />
              {translate('resources.users.sessions.walkVeryHard')}
            </Typography>
            break;
          case "acceleration":
            typeName = <Typography noWrap>
              <OutboundIcon sx={{ fontSize: '.8em', verticalAlign: 'text-top' }} color="warning" />
              {translate('resources.users.sessions.walkAcceleration')}
            </Typography>
            break;
          default:
            typeName = ''
        }
        return typeName;
      }

      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{translate('resources.users.sessions.exerciseDuration')}</TableCell>
                <TableCell>{translate('resources.users.sessions.distance')}</TableCell>
                <TableCell>{translate('resources.users.sessions.averagePace')}</TableCell>
                <TableCell>{translate('resources.users.sessions.averageSpeed')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exerciseComponentsData.map((component) => {
                return (
                  <TableRow>
                    <TableCell>{walkComponentTypeName(component)}</TableCell>
                    <TableCell>{moment.utc(1000 * component.duration).format('mm[:]ss')}</TableCell>
                    <TableCell>{component.walkSessionData?.distance}</TableCell>
                    <TableCell>{Math.round(component.walkSessionData?.averagePace / 6 * 1000) / 10}</TableCell>
                    <TableCell>{Math.round(component.walkSessionData?.averageSpeed * 360) / 100}</TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell>{translate('resources.misc.total')}</TableCell>
                <TableCell>{moment.utc(1000 * session.exercisesData.duration).format('mm[:]ss')}</TableCell>
                <TableCell>{generalSessionWalkData?.distance}</TableCell>
                <TableCell>{Math.round(generalSessionWalkData?.averagePace / 6 * 1000) / 10}</TableCell>
                <TableCell>{Math.round(generalSessionWalkData?.averageSpeed * 360) / 100}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    const renderIndoorData = () => {
      const exerciseComponentsData = session.exercisesData.components;

      return (
        <ol>
          {exerciseComponentsData.map((component) => {
            return (
              <li>
                <Chip
                  avatar={<Avatar>
                    {exercisesVariantsList[component.exerciseVariantId]?.categories.map((category) => category.id)}
                  </Avatar>}
                  label={exercisesVariantsList[component.exerciseVariantId]?.name + " - " + moment.utc(1000 * component.duration).format('mm[:]ss')}
                  variant="outlined"
                />
              </li>
            )
          })}
        </ol>
      )
    }


    const renderSessionDetails = () => {
      return (
        <>
          {session.sessionInfo.userFeedback && (
            <>
              <Typography variant="subtitle2" mt={2}>
                <Stack direction="row">
                  {translate('resources.users.sessions.rating')}&nbsp;{/*feedback*/} {session.sessionInfo.userFeedback}
                </Stack>
              </Typography>
            </>
          )}
          {session.sensor && (
            <Typography variant="subtitle2" color="GrayText">
              {translate('resources.users.sessions.sessionWithSensor')}{session.sensor.modelName}
            </Typography>
          )}
          {session.type === "walk" && renderWalkData()}
          {session.type === "indoor" || !session.type && renderIndoorData()}
          {session.sessionInfo.sensorDataTimeseries.hr.avg > 0 && (
            <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
              <Typography variant="subtitle2">
                <Stack direction="row">
                  {translate('resources.users.sessions.heartBeat')}&nbsp;<FavoriteBorderIcon color="success" />
                </Stack>
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}>
                <Typography variant="body1">
                  <Typography variant="caption" color="GrayText">
                    {translate('resources.users.sessions.min')}</Typography> {session.sessionInfo.sensorDataTimeseries.hr.min} <Typography variant="caption" color="GrayText">{translate('resources.users.sessions.bpm')}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <Typography variant="caption" color="GrayText">
                    {translate('resources.users.sessions.avd')}</Typography> {session.sessionInfo.sensorDataTimeseries.hr.avg} <Typography variant="caption" color="GrayText">{translate('resources.users.sessions.bpm')}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <Typography variant="caption" color="GrayText">
                    {translate('resources.users.sessions.max')}</Typography> {session.sessionInfo.sensorDataTimeseries.hr.max} <Typography variant="caption" color="GrayText">{translate('resources.users.sessions.bpm')}
                  </Typography>
                </Typography>
              </Stack>
            </Paper>
          )}
        </>
      )
    }

    return (
      <>
        <ListItemButton
          onClick={handleClick}
          key={session.id}
          sx={{ backgroundColor: open ? "#F5F5F5" : "inherit" }}
        >
          <ListItemIcon>
            <Avatar>
              {
                logsIcons[session.type] ?
                  logsIcons[session.type]
                  : logsIcons["indoor"]
              }
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={renderSessionTitle()}
            secondary={
              <>
                <AccessTimeIcon sx={{ fontSize: "0.8em;" }} />
                &nbsp;{moment.utc(1000 * session.exercisesData.duration).format('mm[:]ss')}
              </>
            }
          />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }}>
              <ListItemText primary={
                renderSessionDetails()
              } />
            </ListItem>
          </List>
        </Collapse>

      </>
    )
  }

  return (
    <List
      sx={{ width: '100%', mb: 2 }}
      component={Card}
      subheader={
        <ListSubheader>
          {translate(`resources.users.sessions.title`)}
        </ListSubheader>
      }
    >
      {sessions.map((session) => (
        <CollapsibleInformationSet session={session} />
      ))}
    </List>

  );
}