import { useTranslate } from 'ra-core';
import { SortPayload, useGetList, useRedirect } from 'react-admin';

import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import ProgramForm from '../program/ProgramForm';
import ProgramDisplay from './ProgramDisplay';

export default function Dashboard(props) {
  const translate = useTranslate();
  const redirect = useRedirect();
  const sort = { field: 'id', order: 'ASC' } as SortPayload;
  const { data, isLoading } = useGetList('AlixMedPrograms', {
    sort
  });

  if (isLoading) { return <CircularProgress size={25} thickness={2} />; }

  return (
    <>
      <Grid
        justifyContent="center"
        alignItems="flex-start"
        container
        sx={{ minHeight: '84vh', maxHeight: "84vh" }}
        overflow='scroll'
      >
        <Grid item lg={8}>
          <Stack
            spacing={2}
            direction="column"
            sx={{ mx: 4, mt: 4, mb: 4 }}
          >
            {data ? data.map((program) => (
              <ProgramDisplay data={program} key={program.id} />
            )) : null}
          </Stack>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '16vh', rowGap: 8}}>
        <ProgramForm />
        <Button variant='contained' onClick={() => redirect('/sessions/generate')}>{translate('pos.menu.generateSessions')}</Button>
      </div>
    </>
  );
}
