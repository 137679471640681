import polyglotI18nProvider from 'ra-i18n-polyglot';
import React from 'react';
import { Admin, CustomRoutes, resolveBrowserLocale, Resource } from 'react-admin';
import {
  buildAuthProvider, buildDataProvider, CognitoGroupList, CognitoUserList, CognitoUserShow
} from 'react-admin-amplify';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { API, graphqlOperation } from '@aws-amplify/api';
import { Amplify } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';

import awsExports from './aws-exports';
import Dashboard from './dashboard/Dashboard';
import exercises from './exercises';
import ExerciseList from './exercises/ExerciseList';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
/* With polyglotI18nProvider, we absolutly need to load all translation file synchronously like this */
import incredibleEnglishMessages from './i18n/incredible/en';
import incredibleFrenchMessages from './i18n/incredible/fr';
import kprepEnglishMessages from './i18n/kprep/en';
import kprepFrenchMessages from './i18n/kprep/fr';
import InviteCodeCreate from './inviteCodes/CodeCreate';
import InviteCodesEdit from './inviteCodes/CodeEdit';
import InviteCodesList from './inviteCodes/CodeList';
import InviteCodesShow from './inviteCodes/CodeShow';
import { Layout, Login, Redirect } from './layout';
import { theme } from './layout/themes';
import ProgramShow from './program/ProgramShow';
import sessions from './sessions';
import SessionsGenerator from './sessions/SessionGenerator';
import UsersEdit from './users/UsersEdit';
import UsersList from './users/UsersList';
import UsersShow from './users/UsersShow';

Amplify.configure({
  ...awsExports,
  fetchOptions: {
    timeout: 120000,
  },
});

const authProvider = buildAuthProvider({
  authGroups: ["superadmin", "admin"],
});

const loadMessages = (locale) => {

  const urlPath = window.location.pathname;
  const programId = urlPath.split('/')[1]; // Program

  let programEnglishMessages = {};
  let programFrenchMessages = {};
  switch (programId) {
    case 'incredible':
      programEnglishMessages = incredibleEnglishMessages
      programFrenchMessages = incredibleFrenchMessages
      break;
    case 'kprep':
      programEnglishMessages = kprepEnglishMessages
      programFrenchMessages = kprepFrenchMessages
      break;
  }
  const messages = {
    en: { ...englishMessages, ...programEnglishMessages },
    fr: { ...frenchMessages, ...programFrenchMessages },
  };

  return messages[locale] ? messages[locale] : messages.en
}

const i18nProvider = polyglotI18nProvider(
  loadMessages,
  resolveBrowserLocale()
);

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    //storageBucket: awsExports.aws_user_files_s3_bucket,
    //storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);

function App() {
  return (
    <Router>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        theme={theme}
        i18nProvider={i18nProvider}
        disableTelemetry
        loginPage={Login}
        layout={Layout}
        dashboard={Dashboard}
        title={"Alix MED"}
      >
        {(permissions) => [
          <Resource name="AlixMedExercises" {...exercises} />,
          <CustomRoutes noLayout>
            <Route path="/" element={<Dashboard />} />,
          </CustomRoutes>,
          <CustomRoutes>
            <Route path="/sessions/generate" element={<sessions.generate />} />
            <Route path="/redirect" element={<Redirect />} />
            <Route path="/:programId/home" element={<ProgramShow />} />
            <Route path="/:programId/invite-codes" element={<InviteCodesList />} />
            <Route path="/:programId/invite-codes/create" element={<InviteCodeCreate />} />
            <Route path="/:programId/invite-codes/:id/show" element={<InviteCodesShow />} />
            <Route path="/:programId/invite-codes/:id/edit" element={<InviteCodesEdit />} />
            <Route path="/:programId/users" element={<UsersList />} />
            <Route path="/:programId/users/:id/show" element={<UsersShow />} />
            <Route path="/:programId/users/:id/edit" element={<UsersEdit />} />
            <Route path="/:programId/sessions/generate" element={<SessionsGenerator />} />
            <Route path="/sessions/generate" element={<SessionsGenerator />} />
            <Route path="/:programId/sessions/exercises" element={<ExerciseList />} />
          </CustomRoutes>,
          permissions.includes("superadmin") ? (
            <Resource
              name="cognitoUsers"
              options={{ label: "Cognito Users" }}
              list={CognitoUserList}
              show={CognitoUserShow}
            />
          ) : null,
          permissions.includes("superadmin") ? (
            <Resource
              name="cognitoGroups"
              options={{ label: "Cognito Groups" }}
              list={CognitoGroupList}
            />
          ) : null,
        ]}
      </Admin>
    </Router>
  );
}

export default App;
