import { isNil, kebabCase, omit, omitBy, pick, uniqueId } from 'lodash';
import { useState } from 'react';
import { Translate, useRedirect, useTranslate } from 'react-admin';
import {
    Control, useFieldArray, UseFieldArrayRemove, useForm, UseFormWatch
} from 'react-hook-form';
import {
    CheckboxElement, SelectElement, SwitchElement, TextFieldElement
} from 'react-hook-form-mui';

import { Check, Close, Error, ExpandMore, PlaylistRemove, Remove } from '@mui/icons-material';
import {
    Accordion, AccordionDetails, AccordionSummary, Alert, Button, CircularProgress, Dialog,
    DialogActions, DialogContent, Grid, Paper, Tooltip, Typography
} from '@mui/material';

import { postAsyncData } from '../utils/useAdminApi';

const questionTypes = [
    { id: 'string', label: 'string' },
    { id: 'number', label: 'number' },
]

const generationModes = [
    'procedural',
    'aiWithRecommendations'
];

const apiList = [
    'openai_assistant'
]

const modelList = [
    'mistralai/Mixtral-8x7B-Instruct-v0.1'
]

const optionProperties = ['indoorSessions', 'walkSessions'];

type ProgramForm = {
    id: string
    displayName: string
    description: string
    generateSessionMode: string
    icon: string
    options: {
        indoorSessions: boolean
        walkSessions: boolean
    }
    questionnaires: Array<{
        id: string
        questions?: Array<{
            id: string
            type: string
            isEnum?: boolean
            isEnumMultiple?: boolean
            enum?: Array<any>
            enumMultiple?: Array<any>
        }>
    }>
    aiParams: {
        recommendations: {
            api: string
            apiId: string
            promptTemplate: string
        }
        session: {
            model: string
            programRules: string
            promptTemplate: string
        }
    }
}

let defaultValues = {
    id: '',
    displayName: '',
    description: '',
    generateSessionMode: 'aiWithRecommendations',
    icon: '',
    options: {
        indoorSessions: true,
        walkSessions: true
    },
    questionnaires: [],
    aiParams: {
        recommendations: {
            api: 'openai_assistant',
            apiId: 'asst_IJKFZh7QZSVliz5AyW6TG3lr',
            promptTemplate: "Conduct a thorough analysis of the provided information, including both patient-specific data and details: {user_data}. Based on the patient's history and the provided list of exercises, offer professional recommendations that strictly adhere to the provided information. Ensure the recommendations address the patient's current condition. The objective of this request is to obtain an optimized paragraph of recommendations."
        },
        session: {
            model: 'mistralai/Mixtral-8x7B-Instruct-v0.1',
            programRules: `Each session must meet these criteria:
1. Duration: Each session should last between 20 minutes and 30 minutes.
2. Structure: Sessions must include:
   - A 'Warmup' section.
   - A substantial 'Exercises' section.
   - A concluding 'Stretching' section.
3. Balance: Exercises should be performed on both sides, with additional focus on the treated side.
4. Series and Rests: Prefer 3 to 4 series of the same exercise for 40 to 60 seconds each, with short breaks (10 to 30 seconds) between them, rather than one series lasting 3 minutes with a 3-minute rest.
5. Rest Duration: Total rest time should not exceed 25% of the session duration. For a 20-minute session, this means a maximum of 5 minutes of rest.`,
            promptTemplate: `Given the list of exercises: {cleaned_data_exercises} and specific recommendations: {cleaned_user_data_recommendations},
create a detailed custom workout plan formatted as JSON (RFC 8259, excluding comments and formulas), using seconds as the unit of time measurement.
The workout plan must adhere to the provided format: {cleaned_session_format_example_str} and Session model.
{program_rules}
Only include 'exerciseVariantId' from the provided list of exercises and specify rest periods in seconds (e.g., 240 seconds for 4 minutes) between exercises.`
        }
    }
} as ProgramForm;


// Convert a value to a specific type
function setType(value: any, type: string) {
    if (type === 'number')
        return isNaN(value) ? undefined : Number(value)
    if (type === 'string')
        return String(value)
    return value
}


export default function ProgramForm({
    edit,
    programData
}: {
    edit?: boolean
    programData?: ProgramForm
}) {
    // Load translation & redirect
    const translate = useTranslate();
    const redirect = useRedirect();

    // Diablog state
    const [open, setOpen] = useState(false);
    const [codeRedirectOpen, setCodeRedirectOpen] = useState(false);
    const [createdProgramId, setCreatedProgramId] = useState('');

    // Form states
    const [status, setStatus] = useState('idle'); // (Form status should be either 'idle', 'loading', 'success', or 'error')
    const [submitError, setSubmitError] = useState<string>('');

    // Replace default values if editing a program
    const programValues: ProgramForm = programData ? {
        ...programData,
        questionnaires: programData.questionnaires ? programData.questionnaires.map(questionnaire => {
            const questions = questionnaire.questions?.map(question => {
                return {
                    ...question,
                    isEnum: question.enum != null || question.enumMultiple != null,
                    isEnumMultiple: question.enumMultiple != null,
                    enum: question.enumMultiple ? question.enumMultiple : question.enum
                }
            });
            questionnaire.questions = questions;
            return questionnaire;
        }) : []
    } : defaultValues;

    // Form controls
    const { control, handleSubmit, watch } = useForm({ defaultValues: programValues });

    // Init questionnaires field array
    const {
        fields: questionnaires,
        append: appendQuestionnaire,
        remove: removeQuestionnaire,
    } = useFieldArray({ control, name: 'questionnaires' });

    // Function to add a questionnaire in the form
    function addQuestionnaire(questionnaireName?: string) {
        const name = questionnaireName || uniqueId('Questionnaire');
        appendQuestionnaire({ id: name, questions: [] })
    }


    // Function called when submitting the form
    function submitHandler(data: ProgramForm, edit?: boolean) {
        setStatus('loading');

        console.log('SUBMITTED DATA', JSON.stringify(data));

        // Parse options
        const parsedOptions = data.options ? pick(data.options, optionProperties) : undefined;

        // Parse questionnaires
        const parsedQuestionnaires = data.questionnaires ? data.questionnaires.map((questionnaire) => {
            if (!questionnaire.questions || questionnaire.questions.length === 0)
                questionnaire.questions = undefined;
            else
                questionnaire.questions = questionnaire.questions.map(question => {
                    if (!question.isEnum) question = omit(question, ['enum', 'enumMultiple']);
                    else {
                        const parsedEnum = (question.enum || []).map(item => setType(item, question.type));
                        if (question.isEnumMultiple) {
                            question.enumMultiple = parsedEnum.filter(v => !isNil(v));
                            question = omit(question, ['enum']);
                        } else {
                            question.enum = parsedEnum.filter(v => !isNil(v));
                            question = omit(question, ['enumMultiple']);
                        }
                    }
                    return omit(question, ['__typename', 'isEnum', 'isEnumMultiple']);
                }) as ProgramForm['questionnaires'][0]['questions'];
            return omit(questionnaire, ['__typename']);
        }) : [];

        // Remove empty fields or "__typename" properties if editing a program
        const parsedData = edit
            ? pick(data, ['id', 'description', 'displayName', 'generateSessionMode', 'icon', 'options', 'questionnaires', 'aiParams'])
            : omitBy(pick(data, ['id', 'description', 'displayName', 'generateSessionMode', 'icon', 'options', 'questionnaires', 'aiParams']), (item) => !item)
        parsedData.id = kebabCase(data.id);

        const submittedData = {
            ...parsedData,
            options: parsedOptions,
            questionnaires: parsedQuestionnaires,
        } as ProgramForm;

        if (edit) {
            if (!programData) {
                setStatus('error');
                setSubmitError(translate('resources.programs.edit.missingProgramId'));
                return;
            }
            // Get program ID from fetched values
            submittedData.id = programData.id;
            updateProgram(submittedData)
        }
        else createNewProgram(submittedData);
    }

    // Async function that calls the API to create a new program
    async function createNewProgram(programData: ProgramForm) {
        try {
            const { data, error } = await postAsyncData("/adminApi/program", programData) as { data: any, error: any };

            if (error) {
                setStatus('error');
                setSubmitError(String(error));
            } else {
                setStatus('success');
                setCreatedProgramId(programData.id);
                setCodeRedirectOpen(true);
            }
        } catch (error) {
            setStatus('error');
            setSubmitError(String(error));
        }
    }

    async function updateProgram(programData: ProgramForm) {
        try {
            const { data, error } = await postAsyncData("/adminApi/program/" + programData.id, programData) as { data: any, error: any };

            if (error) {
                setStatus('error');
                setSubmitError(String(error));
            } else {
                setStatus('success');
                redirect('/' + programData.id + '/home');
            }
        } catch (error) {
            setStatus('error');
            setSubmitError(String(error));
        }
    }

    return (
        <div>
            <Button variant='contained' onClick={() => setOpen(true)}>{translate(edit ? 'resources.programs.editProgram' : 'resources.home.addProgram')}</Button>
            <Dialog
                open={open}
                fullWidth
                maxWidth='xl'
            >
                <DialogContent sx={{backgroundColor: '#F6F6F6'}}>
                    <div>
                        <Typography variant='h5' style={{ paddingBottom: 8 }}>{translate('resources.home.addProgram')}</Typography>
                        {/* Submit response handling */}
                        {status === 'success' && <Alert severity='success'>{translate('resources.programs.createSuccess')}</Alert>}
                        {status === 'error' && <Alert severity='error'>
                            <div>{translate('resources.programs.createError')}:</div>
                            <div>{submitError}</div>
                        </Alert>}

                        <form onSubmit={handleSubmit((data) => submitHandler(data, edit))}
                            style={{ display: "flex", flexDirection: 'column', gap: 16 }} noValidate>
                            <Grid container spacing={2}>
                                <Grid container item spacing={2} xs={12} md={6}>

                                    {/* Basic program fields */}
                                    <Grid item xs={12} xl={5} style={{ display: "flex", flexDirection: 'column' }}>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography variant='body1' textAlign='center'>{translate('resources.programs.properties')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, justifyContent: 'center' }}>
                                                    <TextFieldElement control={control} size='small' name='id' required label={translate('resources.programs.id')} disabled={edit} />
                                                    <TextFieldElement control={control} size='small' required name='displayName' label={translate('resources.programs.displayName')} />
                                                    <TextFieldElement control={control} size='small' multiline name='description' label={translate('resources.programs.description')} />
                                                    <SelectElement control={control} size='small' name='generateSessionMode' label={translate('resources.programs.generateSessionMode')}
                                                        options={generationModes.map(mode => ({ id: mode, label: mode }))} />
                                                    <TextFieldElement control={control} size='small' name='icon' label={translate('resources.programs.icon')} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    {/* API settings */}
                                    <Grid item xs={12}>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography variant='body1' textAlign='center'>{translate('resources.programs.apiSettings.title')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, justifyContent: 'center' }}>
                                                    {/* Recommendations */}
                                                    <SelectElement control={control} size='small' name='aiParams.recommendations.api' label={translate('resources.programs.apiSettings.recommendations.api')}
                                                        options={apiList.map(api => ({ id: api, label: api }))} />
                                                    <TextFieldElement control={control} size='small' name='aiParams.recommendations.apiId' label={translate('resources.programs.apiSettings.recommendations.apiId')} />
                                                    <TextFieldElement control={control} size='small' name='aiParams.recommendations.promptTemplate' multiline label={translate('resources.programs.apiSettings.recommendations.promptTemplate')} />

                                                    {/* Sessions */}
                                                    <SelectElement control={control} size='small' name='aiParams.session.model' label={translate('resources.programs.apiSettings.session.model')}
                                                        options={modelList.map(model => ({ id: model, label: model }))} />
                                                    <TextFieldElement control={control} size='small' name='aiParams.session.programRules' multiline label={translate('resources.programs.apiSettings.session.programRules')} />
                                                    <TextFieldElement control={control} size='small' name='aiParams.session.promptTemplate' multiline label={translate('resources.programs.apiSettings.session.promptTemplate')} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                    </Grid>
                                </Grid>

                                {/* Program questionnaires */}
                                <Grid item xs={12} md={6}>
                                    {/* program options */}
                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Typography variant='body1' textAlign='center'>{translate('resources.programs.options')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, justifyContent: 'center' }}>
                                                <CheckboxElement size='small' control={control} name={`options.indoorSessions`} label={translate('resources.sessions.indoorSession')} />
                                                <CheckboxElement size='small' control={control} name={`options.walkSessions`} label={translate('resources.sessions.walkSession')} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <Typography variant='body1' textAlign='center'>{translate('resources.programs.questionnaires')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', rowGap: 16 }}>
                                                {questionnaires.map((questionnaire, index) => (
                                                    <QuestionnaireField
                                                        control={control}
                                                        questionnaireId={index}
                                                        removeQuestionnaire={removeQuestionnaire}
                                                        translate={translate}
                                                        key={questionnaire.id}
                                                        watch={watch}
                                                    />
                                                ))}
                                                <Button variant='outlined' sx={{ marginTop: 2 }} onClick={() => addQuestionnaire()}>{translate('resources.programs.addQuestionnaire')}</Button>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                            {/* Dialog actions */}
                            <DialogActions>
                                <div style={{ flex: '1 0 0', display: 'flex', alignItems: 'center', gap: 8 }}>
                                    <Button variant='contained' type='submit' autoFocus disabled={status === 'loading'}>{translate(edit ? 'resources.misc.save' : 'resources.misc.create')}</Button>
                                    {status === 'loading' && <CircularProgress size={25} thickness={2} />}
                                    {status === 'success' && <Check color='success' />}
                                    {status === 'error' && <Error color='error' />}
                                </div>
                                <Button color="error" variant='outlined' onClick={() => setOpen(false)} autoFocus>{translate('resources.misc.cancel')}</Button>
                            </DialogActions>
                        </form>
                    </div>

                    {/* Post-creation dialog */}
                    <Dialog open={codeRedirectOpen}>
                        <DialogContent>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: "16px 8px 0 8px", rowGap: 16 }}>
                                <Typography textAlign='center'>{translate('resources.programs.create.creationRedirectText')}</Typography>
                                <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                    <Button color="primary" variant='contained' onClick={() => redirect('/' + createdProgramId + '/invite-codes/create')} autoFocus>{translate('resources.programs.create.toInviteCode')}</Button>
                                    <Button color="primary" variant='contained' onClick={() => redirect('/' + createdProgramId + '/home')} autoFocus>{translate('resources.programs.create.toProgram')}</Button>
                                </DialogActions>
                            </div>
                        </DialogContent>
                    </Dialog>

                </DialogContent>
            </Dialog>
        </div >
    )
}

// Dynamic questionnaire field
function QuestionnaireField({
    questionnaireId,
    control,
    removeQuestionnaire,
    translate,
    watch
}: {
    questionnaireId: number
    control: Control<ProgramForm, any>
    removeQuestionnaire: UseFieldArrayRemove
    translate: Translate,
    watch: UseFormWatch<ProgramForm>
}) {
    const { fields: questions, append, remove: removeQuestion } = useFieldArray({ control, name: `questionnaires.${questionnaireId}.questions` });

    function addQuestion(questionName?: string) {
        const name = questionName || uniqueId('Question');
        append({ id: name, type: 'string' })
    }

    return (
        <Paper key={questionnaireId} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, padding: "16px 8px" }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TextFieldElement size='small' control={control}
                    name={`questionnaires.${questionnaireId}.id`}
                    label={translate('resources.programs.questionnaireId')} required />
                <Tooltip title={translate('resources.programs.removeQuestionnaire')} arrow placement='top'>
                    <Button onClick={() => removeQuestionnaire(questionnaireId)} sx={{ minWidth: 0 }}>
                        <PlaylistRemove color='error' />
                    </Button>
                </Tooltip>
            </div>
            {questions.length > 0 && <Typography variant='body1' style={{ paddingTop: 2 }}>{translate('resources.programs.questions')}</Typography>}
            {questions.map((question, qId) => (
                <QuestionField
                    {...{ questionnaireId, control, removeQuestion, translate, watch }}
                    questionId={qId}
                    key={question.id}
                />
            ))}
            <Button variant='outlined' onClick={() => addQuestion()}>{translate('resources.programs.addQuestion')}</Button>
        </Paper>
    )
}

// Dynamic question field
function QuestionField({
    questionnaireId,
    questionId,
    control,
    removeQuestion,
    translate,
    watch
}: {
    questionnaireId: number
    questionId: number
    control: Control<ProgramForm, any>
    removeQuestion: UseFieldArrayRemove
    translate: Translate
    watch: UseFormWatch<ProgramForm>
}) {
    const { fields: enums, append, remove: removeEnum } = useFieldArray({ control, name: `questionnaires.${questionnaireId}.questions.${questionId}.enum` });

    function addEnum(enumName?: string) {
        const value = enumName || uniqueId('Enum');
        append(value);
    }

    const questionType = watch(`questionnaires.${questionnaireId}.questions.${questionId}.type`);
    const isEnum = watch(`questionnaires.${questionnaireId}.questions.${questionId}.isEnum`);

    return (
        <div key={questionId} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <TextFieldElement size='small' control={control}
                    name={`questionnaires.${questionnaireId}.questions.${questionId}.id`}
                    label={translate('resources.programs.questionId')} sx={{ flex: '1 1 0' }} required />
                <SelectElement control={control} size='small'
                    name={`questionnaires.${questionnaireId}.questions.${questionId}.type`}
                    options={questionTypes} label={translate('resources.programs.type')} required />
                <Tooltip title={translate('resources.programs.removeQuestion')} arrow placement='top'>
                    <Button onClick={() => removeQuestion(questionId)} sx={{ minWidth: 0 }}>
                        <Close color='error' />
                    </Button>
                </Tooltip>
            </div>
            <Grid container>
                <Grid item xs={4}>
                    <SwitchElement control={control}
                        name={`questionnaires.${questionnaireId}.questions.${questionId}.isEnum`}
                        label={translate('resources.programs.enum')} />
                    {isEnum && <SwitchElement control={control}
                        name={`questionnaires.${questionnaireId}.questions.${questionId}.isEnumMultiple`}
                        label={translate('resources.programs.enumMultiple')} />}
                </Grid>
                <Grid item xs={8}>
                    {isEnum &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginLeft: 64 }}>
                            {enums && enums.map((e, enumId) => {
                                return (
                                    <EnumField
                                        {...{ questionnaireId, questionId, removeEnum, translate, watch, control, questionType }}
                                        enumId={enumId}
                                        key={e.id}
                                    />
                                )
                            })}
                            <Button size='small' variant='outlined' sx={{ marginRight: '44px' }} onClick={() => addEnum()}>{translate('resources.programs.addEnum')}</Button>
                        </div>}
                </Grid>
            </Grid>
        </div>
    )
}

// Enum field
function EnumField({
    questionnaireId,
    questionId,
    questionType,
    enumId,
    control,
    removeEnum,
    translate,
    watch
}: {
    questionnaireId: number
    questionId: number
    questionType: string
    enumId: number
    control: Control<ProgramForm, any>
    removeEnum: UseFieldArrayRemove
    translate: Translate
    watch: UseFormWatch<ProgramForm>
}) {
    return (
        <div key={enumId} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <TextFieldElement size='small' control={control} type={questionType}
                name={`questionnaires.${questionnaireId}.questions.${questionId}.enum.${enumId}`}
                label={translate('resources.misc.value')} sx={{ flex: '1 1 0' }} required />
            <Tooltip title={translate('resources.programs.removeEnum')} arrow placement='top'>
                <Button onClick={() => removeEnum(enumId)} sx={{ minWidth: 0 }}>
                    <Remove color='error' />
                </Button>
            </Tooltip>
        </div>
    )
}