import { Amplify } from "aws-amplify";

import { AppBar, Logout, UserMenu, useTranslate } from 'react-admin';
import {
    Box,
    Chip,
    Typography,
    useMediaQuery,
    Theme,
} from '@mui/material';

const CustomUserMenu = () => (
    <UserMenu>
        {/*<ConfigurationMenu />*/}
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {

    const awsExports = require('../aws-exports.js')
    const amplifyEnv = awsExports.default?.aws_content_delivery_bucket?.match(/.*-(\w+)/)[1]

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={2}
            userMenu={<CustomUserMenu />}
            alwaysOn={true}
        >
            {
                amplifyEnv != "prod" &&
                <Chip label={amplifyEnv} color="error" size="small" sx={{ mr: 1 }} />
            }
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    );
};

export default CustomAppBar;