const customMessages = {
  program: {
    params: {
      knee: "Operated knee",
      flexion: "Flexion (°)",
      extension: "Extension (°)",
      effusion: "Effusion (°)",
      flessum: "Flesum level",
      walkingTestDuration: "Walking duration (s)",
      left: "left",
      right: "right"
    },
  },
};

export default customMessages;