import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from '@mui/material';
import { theme } from '../layout/themes';
import { useRedirect } from 'react-admin';

export default function InviteCodeDisplay(props) {
  const redirect = useRedirect();
  const program = props.data

  const handleClick = () => {
    redirect(`/${program.id}/home`);
  }

  return (
    <Card sx={{ minWidth: 275 }} style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.contrastText }}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Typography variant="h4">
            {program.displayName}
          </Typography>
          <Typography variant="body2">
            <Typography variant="body1" component="span">{program.description}</Typography>
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
  );
}