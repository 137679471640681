import * as React from 'react';
import { Layout, LayoutProps, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';

const MySidebar = (props) => (
  <Sidebar
      sx={{
          mt: '0 !important',
          "& .RaSidebar-fixed": {
            backgroundColor: "#F1EEE6",
        },
      }}
      appBarAlwaysOn={true}
      {...props}
  />
);

export default (props: LayoutProps) => {
  return (
    <>
      <Layout {...props} appBar={AppBar} menu={Menu} sidebar={MySidebar} />
    </>
  );
};