const customMessages = {
  program: {
    params: {
      knee: "Genou traité",
      walkingTestDuration: "Durée de marche (s)",
      left: "gauche",
      right: "droit"
    },
    questionnaires: {
      walkingTest: "Test de marche",
      kneeRom: "Genou",
    }
  }
};

export default customMessages;
