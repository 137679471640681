import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useEffect, useState } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';
import { SwitchElement, TextFieldElement } from 'react-hook-form-mui';
import { Calendar } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useParams } from 'react-router-dom';

import { ArrowBack, Check, ErrorOutline } from '@mui/icons-material';
import {
  Alert, Button, CircularProgress, Grid, Input, Paper, TextField, Typography
} from '@mui/material';

import { postAsyncData, useFetchData } from '../utils/useAdminApi';
import { InviteCodeStructure } from './CodeCreate';

dayjs.extend(LocalizedFormat)
dayjs.extend(customParseFormat);
require('dayjs/locale/fr');


export default function InviteCodeEdit() {
  // Load translation & redirection
  const translate = useTranslate();
  const redirect = useRedirect();

  // Get and set locale
  let locale = translate('resources.locale.prefix');
  if (locale === 'resources.locale.prefix') locale = 'fr-FR'; // Set default locale if undefined in i18n
  dayjs.locale(locale);

  // Get programId
  const { programId, id: inviteCodeId } = useParams();

  // Fetch invite code data
  const { data: inviteCodeData, loading: fetchingCodeData, error: codeFetchError } = useFetchData("/adminApi/program/" + programId + "/invitecodes/" + inviteCodeId) as { data: InviteCodeStructure, loading: boolean, error: any };
  const inviteCodeHash = JSON.stringify(inviteCodeData);

  // Init form status state
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState('');

  // Form control
  const { control, handleSubmit, watch, reset } = useForm({ defaultValues: { ...inviteCodeData } });
  const watchedFields = watch();

  // Reset default form values when programId from params changes
  useEffect(() => {
    reset({
      ...watchedFields,
      ...inviteCodeData,
      expiresAt: inviteCodeData && inviteCodeData.withExpiration
        ? dayjs.unix(inviteCodeData.expiresAt as number).unix()
        : dayjs().add(1, 'day').endOf('day').unix() * 1000,
      userValidationMethod: inviteCodeData && inviteCodeData.withUserValidation
        ? inviteCodeData.userValidationMethod === 'auto'
        : false
    });
  }, [programId, inviteCodeId, inviteCodeHash])

  // Form submit handler
  function submitHandler(formData: InviteCodeStructure) {
    setStatus('loading');
    const parsedFormData = prepareInviteCodeValues({ formData });
    updateInviteCode(parsedFormData);
  }

  // Generate invite code values for the database
  function prepareInviteCodeValues({ formData }: { formData: InviteCodeStructure }) {
    // Define a new invite code Object
    let parsedFormData = {
      ...formData,
      maxUses: formData.withCounts && formData.maxUses !== undefined ? formData.maxUses : null,
      uses: formData.withCounts ? inviteCodeData.uses || 0 : null,
      expiresAt: formData.withExpiration ? formData.expiresAt : null,
      userValidationMethod: formData.withUserValidation && typeof formData.userValidationMethod === 'boolean'
        ? formData.userValidationMethod ? 'auto' : 'manual'
        : null
    } as InviteCodeStructure;

    return parsedFormData;
  }

  async function updateInviteCode(codeData: InviteCodeStructure) {
    try {
      const { data, error: postError } = await postAsyncData("/adminApi/program/" + programId + "/invitecodes/" + inviteCodeId, codeData) as { data: any, error: any };

      if (postError) {
        setStatus('error');
        setError(String(postError));
      }
      else {
        setStatus('success');
        redirect('/' + programId + '/invite-codes');
      }

    } catch (error) {
      setStatus('error');
      setError(String(error));
    }
  }

  if (!programId || !inviteCodeId || fetchingCodeData || !inviteCodeData) return (<CircularProgress />);
  if (codeFetchError) return <Alert severity='error'>{codeFetchError}</Alert>

  return (
    <Paper style={{ padding: 16, margin: "16px 8px" }}>
      {/* Go back button */}
      <div style={{ display: 'flow-root' }}>
        <Button sx={{ float: 'left' }} startIcon={<ArrowBack />} onClick={() => redirect('/' + programId + '/invite-codes')}>{translate('resources.misc.goBack')}</Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 16 }}>
        <Typography variant='h5'>{translate('resources.codes.edit.title')}</Typography>
        <form style={{ display: 'flex', flexDirection: 'column', width: '100%', rowGap: 16 }} onSubmit={handleSubmit((data) => submitHandler(data))}>
          <Grid container spacing={8}>

            {/* Basic properties */}
            <Grid item xs={12} md={8} style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <TextFieldElement control={control} name='name' required size='small' fullWidth
                label={translate('resources.codes.name')} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SwitchElement control={control} name='withUserValidation'
                  label={translate('resources.codes.create.withUserValidation')} />
                {watchedFields.withUserValidation &&
                  <SwitchElement
                    control={control} label={translate(`resources.codes.${watchedFields.userValidationMethod ? 'auto' : 'manual'}`)}
                    name='userValidationMethod' />
                }
              </div>

              {/* Limit fields */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SwitchElement control={control} name='withCounts'
                  label={translate('resources.codes.create.withCounts')} />
                {watchedFields.withCounts &&
                  <Controller
                    control={control}
                    name='maxUses'
                    render={({ field }) => (
                      <TextFieldElement control={control} name='maxUses' required type='number' size='small' fullWidth
                        label={translate('resources.codes.create.maxUses')}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          field.onChange(newValue < 0 ? 0 : newValue);
                        }}
                      />
                    )}
                  />
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SwitchElement control={control} name='withExpiration'
                  label={translate('resources.codes.create.withExpiration')} />
                {watchedFields.withExpiration &&
                  <Controller
                    control={control}
                    name='expiresAt'
                    render={({ field }) => (
                      <Calendar
                        value={field.value}
                        weekStartDayIndex={1}
                        minDate={new Date().toISOString()}
                        plugins={[<TimePicker position='bottom' />]}
                        onChange={(date) => { field.onChange(date?.toUnix() as number * 1000) }}
                      />
                    )}
                  />
                }
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField variant='standard' fullWidth label={translate('resources.codes.id')} InputProps={{ readOnly: true, disableUnderline: true }} value={inviteCodeData.id || '?'} />
              <TextField variant='standard' fullWidth label={translate('resources.codes.status')} InputProps={{ readOnly: true, disableUnderline: true }} value={inviteCodeData.codeStatus || '?'} />
              <TextField variant='standard' fullWidth label={translate('resources.codes.uses')} InputProps={{ readOnly: true, disableUnderline: true }} value={inviteCodeData.withCounts ? inviteCodeData.uses || 0 : '-'} />
              <TextField variant='standard' fullWidth label={translate('resources.codes.createdAt')} InputProps={{ readOnly: true, disableUnderline: true }} value={dayjs(inviteCodeData.createdAt).format('LLLL') || '?'} />
            </Grid>
          </Grid>

          {/* Submit response handling */}
          {(status === 'success' || status === 'error') &&
            <div style={{ padding: '16px 0' }}>
              {status === 'success' && <Alert severity='success'>{translate('resources.codes.edit.editSuccess')}</Alert>}
              {status === 'error' && <Alert severity='error'>
                <div>{translate('resources.codes.edit.editError')}:</div>
                <div>{error}</div>
              </Alert>}
            </div>
          }

          {/* Submit button */}
          <div style={{ flex: '1 0 0', display: 'flex', alignItems: 'center', gap: 8, padding: "0 8px" }}>
            <Button variant='contained' type='submit' autoFocus disabled={status === 'loading'}>{translate('resources.misc.save')}</Button>
            {status === 'loading' && <CircularProgress size={25} thickness={2} />}
            {status === 'success' && <Check color='success' />}
            {status === 'error' && <ErrorOutline color='error' />}
          </div>

        </form>
      </div>
    </Paper>

  );
}