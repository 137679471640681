import * as React from 'react';
import {
  DashboardMenuItem, Menu, MenuItemLink, MenuProps, SortPayload, useGetList, useSidebarState,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { ArrowBack, Ballot, EventNote } from '@mui/icons-material';
import { CircularProgress, Divider } from '@mui/material';
import Box from '@mui/material/Box';

import exercises from '../exercises';
import inviteCodes from '../inviteCodes';
import program from '../program';
import sessions from '../sessions';
import users from '../users';
import SubMenu from './SubMenu';

type MenuName = 'menuPrograms' | 'menuExercises' | 'menuSessions' | 'menuCodes';

const CustomMenu = ({ dense = false }: MenuProps) => {
  const location = useLocation();
  const programId = location.pathname.split("/")[1]

  const [state, setState] = React.useState({
    menuPrograms: true,
    menuExercises: false,
    menuSessions: false,
    menuCodes: false,
  });

  const translate = useTranslate();

  const sort = { field: 'id', order: 'ASC' } as SortPayload;
  const { data, isLoading, error } = useGetList('AlixMedPrograms', {
    sort
  });

  if (isLoading) {
    return <CircularProgress size={25} thickness={2} />;
  }
  const programsList = data;
  if (error) {
    return <div>ERROR</div>;
  }

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  if (programId) {
    return (
      <Menu>
        <MenuItemLink
          to={'/'}
          key={"home"}
          state={{ _scrollToTop: true }}
          primaryText={translate('pos.menu.home')}
          leftIcon={<ArrowBack />}
          dense={dense}
        />

        <Divider />
        {/*<DashboardMenuItem />*/}
        {programId ?
          <MenuItemLink
            to={`/${programId.toLowerCase()}/home`}
            key={"program"}
            state={{ _scrollToTop: true }}
            primaryText={translate('pos.menu.program')}
            leftIcon={<Ballot />}
            dense={dense}
          />
          : null
        }

        {programsList && programId ? programsList.filter((program) => programId.toLowerCase() == program.id.toLowerCase()).map((program) => (
          <Box key={"list"}>
            <Divider />
            <MenuItemLink
              to={`/${program.id.toLowerCase()}/invite-codes`}
              key={"codes"}
              state={{ _scrollToTop: true }}
              primaryText={"Codes d'invitation"}
              leftIcon={<inviteCodes.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={`/${program.id.toLowerCase()}/users`}
              key={"users"}
              state={{ _scrollToTop: true }}
              primaryText={"Patients"}
              leftIcon={<users.icon />}
              dense={dense}
            />
          </Box>
        )) : null}
        {programsList && programId ? programsList.filter((program) => programId.toLowerCase() == program.id.toLowerCase()).map((program) => (
          <SubMenu
            handleToggle={() => handleToggle('menuSessions')}
            isOpen={state.menuSessions}
            name="pos.menu.sessions"
            isTranslated={false}
            icon={<sessions.icon />}
            dense={dense}
            key={"sessionssubmenu"}
          >
            <MenuItemLink
              to={`/${program.id.toLowerCase()}/sessions/generate`}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.sessions.generate`, {
                smart_count: 2,
              })}
              leftIcon={<sessions.icon />}
              dense={dense}
              key={"sessions"}
            />
            <MenuItemLink
              to={`/${program.id.toLowerCase()}/sessions/exercises`}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.exercises.name`, {
                smart_count: 2,
              })}
              leftIcon={<exercises.icon />}
              dense={dense}
              key={"exercises"}
            />
          </SubMenu>
        )) : null}
      </Menu>
    );
  } else {
    return null;
  }
};

export default CustomMenu;