/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://wncdrbctn2.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        },
        {
            "name": "alixMedRestApi",
            "endpoint": "https://9ag0jgpsz9.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://q3fok5ey5bdivecdnefuoldzom.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-3:b2fc01e8-e405-4b82-bff6-d50726e2a37d",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_5hJlzPoCa",
    "aws_user_pools_web_client_id": "2uhg6ej39kl7osg8bl9hbqlmf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "alix-med-hosting-bucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-3",
    "aws_content_delivery_url": "https://d11w0fh4ljwkbs.cloudfront.net",
    "aws_user_files_s3_bucket": "alix-med-storage-bucket142822-dev",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
